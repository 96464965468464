import React from 'react';

const Footer = () => {
    return (
        <div className="footer-container-wrapper">
        <div className="footer-container container-fluid">

            <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-global_group " data-widget-type="global_group" data-x="0" data-w="12">
                        <div className="" data-global-widget-path="generated_global_groups/73133864836.html"><div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-cell footerPadding" data-widget-type="cell" data-x="0" data-w="12">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_9746936" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                                        <div className="row-fluid ">
                                            <div className="span3 widget-span widget-type-custom_widget footerLinks" data-widget-type="custom_widget" data-x="0" data-w="3">
                                                <div id="hs_cos_wrapper_module_947152943" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><p style={{color:"#333"}}><a href="/patients">Patients</a></p>
                                                    <span style={{borderBottom:"1px solid #c2c2c2", display: "block", width:"200px"}}> </span>

                                                    <p style={{color:"#bdbdbd"}}>
                                                        <a href="/prostate-cancer">Prostate Cancer</a> <br />
                                                            <a href="/client-referral">Client Referral Portal</a> <br />
                                                                <a href="/patients#hs_cos_wrapper_module_1536768030022141">FAQs</a>
                                                            </p></div>

                                                </div>
                                                <div className="span3 widget-span widget-type-custom_widget footerLinks" data-widget-type="custom_widget" data-x="3" data-w="3">
                                                    <div id="hs_cos_wrapper_module_599825296" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><p style={{color:"#333"}}><a href="/physician">Physicians</a></p>
                                                        <span style={{borderBottom: "1px solid #c2c2c2", display: "block", width: "200px"}}> </span>

                                                        <p style={{color: "#bdbdbd"}}>
                                                            <a href="/science-behind-the-score">Scientific</a> <br />
                                                                <a href="/what-is-the-4kscore">What Is 4Kscore®?</a> <br />
                                                                    <a href="/listed-physician">Become a Listed Physician</a> <br />
                                                                        <a href="/physician#hs_cos_wrapper_module_1576529742274905">FAQs</a>
                                                                    </p></div>

                                                            </div>
                                                            <div className="span3 widget-span widget-type-custom_widget footerLinks" data-widget-type="custom_widget" data-x="6" data-w="3">
                                                                <div id="hs_cos_wrapper_module_473805975" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><p style={{color:"#333"}}><a href="#" style={{cursor: "text"}}>Resources</a></p>
                                                                    <span style={{borderBottom:"1px solid #c2c2c2", display:"block", width:"200px"}}> </span>

                                                                    <p style={{color:"#bdbdbd"}}>
                                                                        <a href="/locators/4kscore-test-center">Find a 4Kscore® Draw Center</a><br />
                                                                            <a href="/privacy">Privacy Practices</a><br />
                                                                                <a href="/terms-of-use">Terms of Use</a><br />
                                                                                    <a href="https://www.bioreference.com/wp-content/uploads/2022/04/BRL-Cookies-Policy-Final-3-22-22.pdf?__hstc=30177824.a647e8a79626a4a4d1a320f40320fe86.1652458998648.1652808197025.1652813534362.8&amp;__hssc=30177824.1.1652813534362&amp;__hsfp=2952151544" target="_blank" rel="noopener noreferrer">Cookie Policy</a><br />
                                                                                        <a href="javascript:Optanon.ToggleInfoDisplay()">Cookie Settings</a><br/>
                                                                                        <a href="/contact">Contact Us</a>
                                                                                    </p></div>

                                                                            </div>
                                                                            <div className="span3 widget-span widget-type-cell " data-widget-type="cell" data-x="9" data-w="3">

                                                                                <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                                                                                    <div className="row-fluid ">
                                                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                                            <div id="hs_cos_wrapper_module_718985205" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                                                                                    <div className="row-fluid ">
                                                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                                            <div id="hs_cos_wrapper_module_16521240434011823" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><img className="footerLogo" style={{width: "100%", maxWidth: "355px"}} src="https://www.4kscore.com/hubfs/Assets/4kscore-2022-grey.png" /></div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                                                                    <div className="row-fluid ">
                                                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                                            <div id="hs_cos_wrapper_module_611073252" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                                                                                    <div className="row-fluid ">
                                                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                                                            <div id="hs_cos_wrapper_module_204563575" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="footerIcons" style={{marginBottom:"20px"}}>
                                                                                                <a target="_blank" href="https://www.facebook.com/4kscore" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>&nbsp;&nbsp;
                                                                                                <a target="_blank" href="https://twitter.com/4Kscore" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>&nbsp;&nbsp;
                                                                                                <a target="_blank" href="https://www.linkedin.com/company/opko-health-inc./" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;
                                                                                                <a target="_blank" href="https://www.youtube.com/user/4kscore" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                                                                                            </div>

                                                                                                <p className="copywrite">
                                                                                                    © 4Kscore 2022
                                                                                                </p></div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                </div>

                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
    )
}
export default Footer;