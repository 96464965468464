import React from 'react';

const CTA = () => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell footer-bg sp_get_started" style={{background: "linear-gradient(to right,#6ea6cd,#0e6aab)"}} data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " style={{height: "20px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1554995448858624" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1554994655262508" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1554994655262508_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 style={{textAlign: "center"}}><span style={{color: "#fff"}}>Find The 4Kscore® Test Near You</span></h2>
                                                <p style={{textAlign: "center"}}><span style={{color: "#fff"}}>You can find a Patient Service Center by using the link below.</span></p>
                                                <div className="sp_home_cta_btns"><span className="hs-cta-wrapper" id="hs-cta-wrapper-076a8db3-ef2c-4e38-95a2-9dde59c1c4e1"><span className="hs-cta-node hs-cta-076a8db3-ef2c-4e38-95a2-9dde59c1c4e1" id="hs-cta-076a8db3-ef2c-4e38-95a2-9dde59c1c4e1" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_cfaed8b2-4918-4de4-bf43-506317c4e089" className="cta_button footerCTAsec" style={{minWidth: "313px", maxWidth: "320px"}} href="/locators/4kscore-test-center" title="Find a 4Kscore Test Draw Center"><span style={{color: "#c32337" }}>Find a 4Kscore<sup>®</sup> Test Draw Center</span></a></span></span></div></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " style={{height: "40px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1554995459448643" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CTA;