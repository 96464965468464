import * as React from "react";
import "../../styles/extra.css";

const RedirectPopup = () => {
  return (  
    <div id="fks-redirect-popup" class="fks-modal">
        <div class="fks-modal-content">
            <p>
                Este sitio web (y las páginas contenidas) estan desactivado. Si se
                necesita asistencia en español u otro idioma, por favor llame sin
                cargas a 800 229 5227.
            </p>
            <a href="#" class="fks-modal-close">
                close
            </a>
        </div>
    </div>      
  );
};

export default RedirectPopup;
