import React from 'react';
import { Link } from "gatsby";


const MainNav = ({ menu }) => {
    return (
        <ul role="menu">
            {menu.map(mainItem =>
                !mainItem.parentId
                    ? (
                        <li key={mainItem.id} className={`hs-menu-item hs-menu-depth-1 ${mainItem.childItems.nodes.length > 0 ? "hs-item-has-children" : ""}`} role="none">
                            {
                                mainItem.target !== null
                                    ? (
                                        <a href={mainItem.url} target={mainItem.target} className={mainItem.cssClasses.join(' ')}>
                                            {mainItem.label}
                                        </a>
                                    )

                                    : (
                                        <Link to={mainItem.url} activeClassName="active" className={mainItem.cssClasses.join(' ')}>
                                            {mainItem.label}
                                        </Link>
                                    )
                            }

                            {mainItem.childItems.nodes.length !== 0 ? (
                                <ul role="menu" className="hs-menu-children-wrapper">
                                    {mainItem.childItems.nodes.map(childItem => (
                                        <li className="hs-menu-item hs-menu-depth-2" key={childItem.id} role="none">
                                            <Link to={childItem.url} activeClassName="active">
                                                {childItem.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </li>
                    )
                    : null
            )
            }
        </ul>
    )
}

export default MainNav;