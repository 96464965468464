import React from 'react';
import MainNav from "./Header/MainNav";
import TopNav from './Header/TopNav';
import logo from "../../images/header/4kscore-2022.png";
class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var mobitrigger = document.getElementById("mobitrigger");
        var menuWrapper = document.getElementById("hs_menu_wrapper_module_288059038_");

        if (mobitrigger !== null) {
            mobitrigger.addEventListener("click", function () {
                if (menuWrapper.classList.contains("visible")) {
                    menuWrapper.classList.remove("visible");
                    menuWrapper.setAttribute("style", "display:none;");
                } else {
                    menuWrapper.classList.add("visible");
                    menuWrapper.setAttribute("style", "display:block;");
                }
            });
        }
    }
    render() {
        return (
            <div className="header-container-wrapper">
                <div className="header-container container-fluid">

                    <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-global_group " data-widget-type="global_group" data-x="0" data-w="12">
                                <div className="" data-global-widget-path="generated_global_groups/73140539216.html"><div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget sp_top_nav" data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <TopNav menu={this.props.topMenu.wpMenu.menuItems.nodes} />

                                        </div>
                                    </div>
                                </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                                        <div className="row-fluid " style={{ overflow: "hidden" }}>
                                            <div className="span12 widget-span widget-type-cell desktopMenu" data-widget-type="cell" data-x="0" data-w="12">

                                                <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                                                    <div className="row-fluid ">
                                                        <div className="span3 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="3">
                                                            <div id="hs_cos_wrapper_module_16521240630681838" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                                <a href="/"><img src={logo} style={{ width: "100%", maxWidth: "300px", display: "block", margin: "0" }} /></a>
                                                            </div>

                                                        </div>
                                                        <div className="span9 widget-span widget-type-custom_widget headerlinks" style={{ textAlign: "right" }} data-widget-type="custom_widget" data-x="3" data-w="9">
                                                            <div id="hs_cos_wrapper_module_77433099" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                                <span id="hs_cos_wrapper_module_77433099_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_module_77433099_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id="73143640757" aria-label="Navigation Menu">
                                                                    <MainNav menu={this.props.wpMenu.menuItems.nodes} />
                                                                </div></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget hidden-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_768165447" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-cell visible-phone sp_mobile_top" data-widget-type="cell" data-x="0" data-w="12">

                                                <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_16521274307051937" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                                <a href="/"><img src={logo} style={{ width: "100%", display: "block", margin: "0" }} class="mmmlogo" /></a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget custom-menu-primary js-enabled" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_288059038" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                                <span id="hs_cos_wrapper_module_288059038_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="mobitrigger" className="mobile-trigger"><i></i></div><div id="hs_menu_wrapper_module_288059038_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id="73143640757" aria-label="Navigation Menu">
                                                                    <ul role="menu">
                                                                        <li className="hs-menu-item hs-menu-depth-1 hs-item-has-children" role="none"><a href="/patients" aria-haspopup="true" aria-expanded="false" role="menuitem">Patients</a> <div className="child-trigger"><i></i></div></li>
                                                                        <li className="hs-menu-item hs-menu-depth-1 hs-item-has-children" role="none"><a href="https://www.4kscore.com/physician" aria-haspopup="true" aria-expanded="false" role="menuitem">Physicians</a> <div className="child-trigger"><i></i></div></li>
                                                                        <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.4kscore.com/science-behind-the-score" role="menuitem">Scientific</a></li>
                                                                        <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.4kscore.com/insurance" role="menuitem">Insurance</a></li>
                                                                    </ul>
                                                                </div></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ clear: "both" }}></div>
            </div>
        )
    }
}

export default Header;