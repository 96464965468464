import * as React from "react";
import { withPrefix } from "gatsby";
import "../../styles/Spoke.min.css";
import "../../styles/layout.min.css";
import "../../styles/extra.css";
import Helmet from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import RedirectPopup from "./RedirectPopup";
import CTA from "./CTA";
import { useMainMenuQuery } from "../../hooks/useMainMenuQuery";
import { useTopMenuQuery } from "../../hooks/useTopMenuQuery";

const Layout = ({ children }) => {
    const { site, wpMenu } = useMainMenuQuery();
    const topMenu = useTopMenuQuery();
    return (
        <div>
            <link href={withPrefix('https://use.fontawesome.com/releases/v4.7.0/css/font-awesome-css.min.css')} rel="stylesheet" type="text/css" />
            <link href={withPrefix('https://use.fontawesome.com/releases/v5.2.0/css/all.css')} rel="stylesheet" type="text/css" />
            <Header site={site} wpMenu={wpMenu} topMenu={topMenu} />
            {children}
            <CTA />
            <Footer />
            <RedirectPopup />
            <Helmet>
                <link href={withPrefix('masterslider.main.css')} rel="stylesheet" type="text/css" />
                <link href={withPrefix('fresco.css')} rel="stylesheet" type="text/css" />
                <link href={withPrefix('slick-theme.css')} rel="stylesheet" type="text/css" />
                <link href={withPrefix('slick.css')} rel="stylesheet" type="text/css" />
                <link rel="shortcut icon" href={withPrefix('favicon.png')} />                
                <meta charset="utf-8" />
            </Helmet>
        </div>
    )
};

export default Layout;