import React from 'react';
import { Link } from "gatsby";

<div id="hs_cos_wrapper_module_369700471" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_369700471_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><a href="https://www.4kscore.com/locators/4kscore-test-center">Find a 4Kscore® Draw Center</a>&nbsp; <a href="https://brli.insightdx.com/doctors/framepage.asp" rel="noopener noreferrer" target="_blank">Physician Login </a><a href="https://www.4kscore.com/contact" rel=" noopener noreferrer">Contact Us</a></span></div>
const TopNav = ({ menu }) => {
    return (
        <div id="hs_cos_wrapper_module_369700471" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module">
            <span id="hs_cos_wrapper_module_369700471_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
            {menu.map(mainItem =>
                mainItem.target !== null
                    ? (
                        <a href={mainItem.url} target={mainItem.target} className={mainItem.cssClasses.join(' ')} key={mainItem.id}>
                            {mainItem.label}
                        </a>
                    )
                    : (
                        <Link to={mainItem.url} activeClassName="active" className={mainItem.cssClasses.join(' ')} key={mainItem.id}>
                            {mainItem.label}
                        </Link>
                    )
            )
            }
            </span>
        </div>
    )
}

export default TopNav;