import { useStaticQuery, graphql } from "gatsby"

export const useTopMenuQuery = ()=> {
    const data = useStaticQuery(graphql`
        query TopMenuHeaderQuery {
            wpMenu(name: {eq: "topMenu"}) {
                menuItems {
                    nodes {
                        label
                        url
                        parentId
                        id
                        cssClasses
                        target
                        childItems {
                            nodes {
                                label
                                url
                                parentId
                                id
                                cssClasses
                                target
                            }
                        }
                    }
                }
            }
        }
    `)
    return data;
}